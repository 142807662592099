import { createSlice } from "utils/@reduxjs/toolkit";

import { PayloadAction } from "@reduxjs/toolkit";
import { LoggedInUser } from "app/containers/Auth/types";
import set from "lodash/set";
import { ContainerState, ImageType, ProfileData } from "../types";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  image: {
    value: null,
    error: "",
  },
  firstName: {
    value: "",
    error: "",
  },
  lastName: {
    value: "",
    error: "",
  },
  phoneNumber: {
    value: "",
    error: "",
  },
  email: {
    value: "",
    error: "",
  },
  language: {
    value: "en",
    error: "",
  },
  error: "",
  isLoading: false,
  isUploading: false,
};

const profileSlice = createSlice({
  name: "profileState",
  initialState,
  reducers: {
    setProfileImage(state, action: PayloadAction<ImageType>) {
      set(state, "image", action.payload);
      set(state.image, "error", "");
    },
    setProfileImageError(state, action: PayloadAction<string>) {
      set(state.image, "error", action.payload);
    },
    setFirstName(state, action: PayloadAction<string>) {
      set(state.firstName, "value", action.payload);
      set(state.firstName, "error", "");
    },
    setLastName(state, action: PayloadAction<string>) {
      set(state.lastName, "value", action.payload);
      set(state.lastName, "error", "");
    },
    setPhoneNumber(state, action: PayloadAction<string>) {
      set(state.phoneNumber, "value", action.payload);
      set(state.phoneNumber, "error", "");
    },
    setEmail(state, action: PayloadAction<string>) {
      set(state.email, "value", action.payload);
      set(state.email, "error", "");
    },
    setLanguage(state, action: PayloadAction<"en" | "he">) {
      set(state.language, "value", action.payload);
      set(state.language, "error", "");
    },
    setFormErrors(
      state,
      action: PayloadAction<Array<{ name: string; error: string }>>
    ) {
      action.payload.forEach((error) => {
        set(state, `${error.name}.error`, error.error);
      });
    },
    updateProfile(state, action: PayloadAction<ProfileData>) {
      set(state, "isLoading", true);
    },
    updatedProfile(state, action: PayloadAction<LoggedInUser>) {
      set(state?.image, "value", action.payload?.image);
      set(state?.firstName, "value", action.payload?.userMeta?.firstName);
      set(state?.lastName, "value", action.payload?.userMeta?.lastName);
      set(state?.phoneNumber, "value", action.payload?.phoneNumber);
      set(state?.email, "value", action.payload?.email);
      set(
        state?.language,
        "value",
        action.payload?.language?.toLowerCase() ?? "en"
      );
      set(state, "isLoading", false);
    },
    updateProfileFailed(state, action: PayloadAction<string>) {
      set(state, "error", action.payload);
      set(state, "isLoading", false);
    },
    uploadImage(
      state,
      action: PayloadAction<{
        file: File;
        callback?: (file: ImageType | string) => void;
      }>
    ) {
      set(state, "isUploading", true);
      set(state.image, "error", "");
    },
    imageUploaded(state, action: PayloadAction<ImageType>) {
      set(state.image, "value", action.payload);
      set(state, "isUploading", false);
    },
    setImage(state, action: PayloadAction<ImageType | null>) {
      set(state.image, "value", action.payload);
    },
    imageUploadFailed(state, action: PayloadAction<string>) {
      set(state.image, "error", action.payload);
      set(state, "isUploading", false);
    },
  },
});

export const { actions, reducer, name: profileSliceKey } = profileSlice;
