import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { selectUser } from "store/fields/permissions/selectors";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import Dashboard from ".";
import { selectHasConsumersAccess } from "../Consumers/redux/selectors";
import { selectHasProfileAccess } from "../Profile/redux/selectors";
import { selectHasUsersAccess } from "../Users/redux/selectors";
import { dashboardSaga } from "./redux/saga";
import { selectHasDashboardAccess } from "./redux/selectors";
import { dashboardSliceKey, reducer } from "./redux/slice";

const DashboardRoutes = () => {
  useInjectReducer({ key: dashboardSliceKey, reducer: reducer });
  useInjectSaga({ key: dashboardSliceKey, saga: dashboardSaga });
  
  const hasDashboardAccess = useSelector(selectHasDashboardAccess);
  const hasUsersAccess = useSelector(selectHasUsersAccess);
  const hasConsumersAccess = useSelector(selectHasConsumersAccess);
  const hasProfileAccess = useSelector(selectHasProfileAccess);
  const user = useSelector(selectUser);

  if (!hasDashboardAccess && user) {
    if (hasUsersAccess) {
      return <Navigate to="/users" />;
    }
    if (hasConsumersAccess) {
      return <Navigate to="/consumers" />;
    }
    if (hasProfileAccess) {
      return <Navigate to="/profile" />;
    }
    return <Navigate to="/no-access" />;
  }

  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
    </Routes>
  );
};

export default DashboardRoutes;
