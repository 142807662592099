import { Logo } from "app/components/Logo";
import React from "react";
import useWindowDimensions from "utils/hooks/useWindowDimensions";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LogoImage from "assets/Image.png";
import { useMatch } from "react-router-dom";

type Props = {
  minWidth: number;
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 16,
  },
  text: {
    marginTop: 16,
    fontSize: 24,
    letterSpacing: ".5px",
    textAlign: "center",
    color: "#3C4858",
  },
}));

export const UnsupportedScreen = ({ minWidth }: Props) => {
  const match = useMatch({path: '/pages/*'})
  const styles = useStyles();
  const { width } = useWindowDimensions();
  if(match) {return null}
  return (
    <Box className={styles.root}>
      <Logo />
      <div
        className={styles.text}
      >{`This site doesn't support window width less than ${minWidth}`}</div>
      <div className={styles.text}>
        Your current window size is{" "}
        <span style={{ fontWeight: "bold" }}>{width}</span>px
      </div>
    </Box>
  );
};
