import { Box, CircularProgress } from "@mui/material";
import React from "react";

export default function PageLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        background: "rgba(255,255,255,0.5)",
      }}
    >
      <CircularProgress
        sx={{
          color: "#4596FF",
        }}
      />
    </Box>
  );
}
