import { PayloadAction } from "@reduxjs/toolkit";
import { FormError, QueryParams } from "app/containers/types";
import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import {
  ConsumerMessageType,
  ConsumerTypes,
  ContainerState,
  MetaDataTypes,
} from "../types";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  consumers: [],
  isLoading: false,
  metaData: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 1,
  },
  formValues: {
    firstName: {
      value: "",
      error: "",
    },
    lastName: {
      value: "",
      error: "",
    },
    email: {
      value: "",
      error: "",
    },
    phoneNumber: {
      value: "",
      error: "",
    },
    status: {
      value: "",
      error: "",
    },
  },
  messageValues: {
    message: {
      value: "",
      error: "",
    },
    subject: {
      value: "",
      error: "",
    },
  },
  isSubmitting: false,
  selectedConsumerIds: [],
  selectedAll: false,
};

const consumerSlice = createSlice({
  name: "consumerState",
  initialState,
  reducers: {
    setFormValues(
      state,
      action: PayloadAction<{ key: string; value: string }>
    ) {
      const { key, value } = action.payload;
      set(state.formValues, `${key}.value`, value);
      set(state.formValues, `${key}.error`, "");
    },
    setMessageValues(
      state,
      action: PayloadAction<{ key: string; value: string }>
    ) {
      const { key, value } = action.payload;
      set(state.messageValues, `${key}.value`, value);
      set(state.messageValues, `${key}.error`, "");
    },
    setMessageErrors(state, action: PayloadAction<Array<FormError>>) {
      const { payload } = action;
      payload.forEach((error) => {
        set(state.messageValues, `${error.name}.error`, error.error);
      });
    },
    resetMessageValues(state) {
      state.messageValues = initialState.messageValues;
    },
    setFormErrors(
      state,
      action: PayloadAction<{ key: string; error: string }>
    ) {
      const { key, error } = action.payload;
      set(state.formValues, `${key}.error`, error);
    },
    sendMessage(state, action: PayloadAction<ConsumerMessageType>) {
      state.isSubmitting = true;
    },
    sendMessageSuccess(state) {
      state.isSubmitting = false;
    },
    sendMessageError(state) {
      state.isSubmitting = false;
    },
    setConsumerId(state, action: PayloadAction<string>) {
      const { payload } = action;
      state.selectedConsumerIds.push(payload);
    },
    removeConsumerId(state, action: PayloadAction<string>) {
      const { payload } = action;
      state.selectedConsumerIds = state.selectedConsumerIds.filter(
        (id) => id !== payload
      );
    },
    setSelectedAll(state, action: PayloadAction<boolean>) {
      state.selectedAll = action.payload;
      state.selectedConsumerIds = [];
    },
    getConsumers(state, action: PayloadAction<QueryParams>) {
      state.isLoading = true;
    },
    setConsumers(
      state,
      action: PayloadAction<{
        consumers: ConsumerTypes[];
        metaData: MetaDataTypes;
      }>
    ) {
      const consumers =
        action.payload?.metaData?.currentPage > 1
          ? [...state.consumers, ...action.payload.consumers]
          : action.payload.consumers;
      state.consumers = consumers;
      state.metaData = action.payload?.metaData;
      state.isLoading = false;
    },
    updateConsumersPage(state, action: PayloadAction<number>) {
      state.metaData.currentPage = action.payload;
    },
    completeGetConsumers(state) {
      state.isLoading = false;
    },
    resetFormValues(state) {
      state.formValues = initialState.formValues;
    },
  },
});

export const { actions, reducer, name: consumerSliceKey } = consumerSlice;
