import { LoadingIndicator } from "app/components/LoadingIndicator";
import * as React from "react";
import { lazyLoad } from "utils/loadable";

export const SettingsLoadable = lazyLoad(
  () => import("./routes"),
  (module) => module.default,
  {
    fallback: (
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingIndicator />
      </div>
    ),
  }
);
export default SettingsLoadable;
