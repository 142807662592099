import { Box, Typography, useTheme } from "@mui/material";
import CustomButton from "app/components/Button";
import TextInput from "app/components/TextInput";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { selectLoading, selectResetPasswordForm } from "../../redux/selectors";
import { actions } from "../../redux/slice";
import { useLgStyles } from "../LoginPage/styles";
import { validate } from "./validator";

export function ChangePassword({ className }) {
  const classes = useLgStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoading);
  const formValues = useSelector(selectResetPasswordForm);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const onSubmit = (e) => {
    e?.preventDefault();
    const errors = validate(formValues);
    if (errors?.length > 0) {
      console.log("errors", errors);
      dispatch(actions.updateResetFormError(errors));
      return;
    }
    if (!token || !email) {
      toast(`${t("AUTH.TOKEN_EMAIL_INVALID")}`);
      return;
    }
    dispatch(
      actions.resetPassword({
        password: formValues.password.value,
        token: token ?? "",
        email: email ?? "",
        callback: () => {
          navigate("/auth/login");
        },
      })
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        padding: "0 20%",
        direction: theme.direction,
      }}
    >
      <form>
        <Typography className={classes.title}>
          {t("AUTH.CHANGE_PASSWORD")}
        </Typography>
        <TextInput
          placeholder={t("AUTH.PASSWORD")}
          containerStyle={{
            width: "100%",
            marginBottom: "9px",
          }}
          type="password"
          disabled={isLoading}
          value={formValues?.password?.value}
          error={
            formValues?.password?.error && t(`${formValues?.password?.error}`)
          }
          onChange={(e) => {
            dispatch(
              actions.updateResetForm({
                value: e.target.value,
                name: "password",
              })
            );
          }}
        />
        <TextInput
          placeholder={t("AUTH.REPEAT_PASSWORD")}
          containerStyle={{
            width: "100%",
            marginBottom: "9px",
          }}
          value={formValues?.confirm_password?.value}
          error={
            formValues?.confirm_password?.error &&
            t(`${formValues?.confirm_password?.error}`)
          }
          onChange={(e) => {
            dispatch(
              actions.updateResetForm({
                value: e.target.value,
                name: "confirm_password",
              })
            );
          }}
          onPaste={(e) => {
            e.preventDefault();
          }}
          type="password"
          disabled={isLoading}
        />
        <CustomButton
          disabled={
            isLoading ||
            !formValues?.password?.value ||
            !formValues?.confirm_password?.value
          }
          type="submit"
          isLoading={isLoading}
          onClick={onSubmit}
          btnStyle={{
            marginTop: "52px",
          }}
        >
          <Typography className={classes.btnText}>
            {t("BUTTONS.SAVE")}
          </Typography>
        </CustomButton>
      </form>
    </Box>
  );
}

export default ChangePassword;
