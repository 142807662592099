/**
 * Homepage selectors
 */

import { RootState } from "types";

import { createSelector } from "@reduxjs/toolkit";

import { PermissionKeys } from "app/containers/Permissions/type";
import { selectCapabilitiesForPermissions } from "store/fields/permissions/selectors";
import { initialState } from "./slice";
import { BarHistory } from "../types";

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.dashboardState || initialState;

export const selectStats = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.stats
);

export const selectHistoryStats = createSelector(
  [selectDomain],
  (dashboardState) => {
    const colors = [
      { type: "REGISTERED", color: "#DCD526" },
      { type: "NEW", color: "#4596FF" },
      { type: "DELAYED", color: "#9FA2A5" },
      { type: "TOTAL", color: "#ECD1B9" },
    ];
    const data = colors.map((color, index) => {
      return {
        color: color.color,
        type: color.type,
        bars: dashboardState.history.items.map((dayData, dayIndex) => {
          const x = dayData.date;
          if (color.type == "REGISTERED") {
            return { x: x, y: Number(dayData.registeredCountSum) || 0.02 };
          }
          if (color.type == "NEW") {
            return { x: x, y: Number(dayData.loggedInCountSum) || 0.02 };
          }

          if (color.type == "DELAYED") {
            return { x: x, y: Number(dayData.delayedCountSum) || 0.02 };
          }

          if (color.type == "TOTAL") {
            return { x: x, y: Number(dayData.totalCountSum) || 0.02 };
          }
        }),
      };
    });
    let minY = Number.POSITIVE_INFINITY;
    let maxY = Number.NEGATIVE_INFINITY;
    data.forEach((entry) => {
      entry.bars.forEach((bar) => {
        const y = Number(bar?.y);
        if (y < minY) {
          minY = y;
        }
        if (y > maxY) {
          maxY = y;
        }
      });
    });

    // Step 2: Prepare a tick values array with whole numbers
    const tickValues: number[] = [];
    for (let i = Math.floor(minY); i <= Math.ceil(maxY); i++) {
      tickValues.push(i);
    }
    const allowZoom = data[0].bars.length > 12

    return { tickValues, data, allowZoom };
  }
);

export const selectHistoryStatsMain = createSelector(
  [selectDomain],
  (dashboardState) => {
    return dashboardState.history;
  }
);

export const selectIsLoading = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.isLoading
);

export const selectTotalUsers = createSelector(
  [selectStats],
  (stats) => stats?.totalUsers
);

export const selectDailyStats = createSelector([selectStats], (stats) => {
  return stats?.daily;
});

export const selectMonthlyStats = createSelector([selectStats], (stats) => {
  return stats?.month;
});

export const selectHasDashboardAccess = createSelector(
  [selectCapabilitiesForPermissions],
  (capabilitiesForPermissions) => {
    return (
      capabilitiesForPermissions[PermissionKeys.DASHBOARD_VIEW_STATS]
        ?.hasReadAccess ||
      capabilitiesForPermissions[PermissionKeys.DASHBOARD_VIEW_STAT_HISTORY]
        ?.hasReadAccess
    );
  }
);
