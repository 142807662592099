import PersonIcon from "@mui/icons-material/Person";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  selectHasProfileAccess,
  selectedUser,
} from "app/containers/Profile/redux/selectors";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Search from "../Search";

export default function Navbar() {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const user = useSelector(selectedUser);
  const hasProfileAccess = useSelector(selectHasProfileAccess);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const signout = () => {
    localStorage.removeItem("sessionToken");
    navigate("/auth/login");
  };

  const pathname = window.location.pathname;

  const shouldShowSearchInput = () => {
    if (pathname === "/") return false;
    if (pathname === "/no-access") return false;
    if (pathname.startsWith("/profile")) return false;
    if (pathname.startsWith("/settings")) {
      const tab = searchParams.get("tab");
      if (!tab) return true;
      if (tab === "1") return true;
      if (tab === "2") return false;
      if (tab === "3") return false;
    }
    if (pathname?.startsWith("/permissions/")) return false;
    return true;
  };

  return (
    <Grid2
      sx={{
        padding: "16px 32px",
      }}
      container
    >
      <Grid2 md={4}>{shouldShowSearchInput?.() && <Search />}</Grid2>
      <Grid2 md={8}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Tooltip title={t("COMMON.PROFILE_SETTINGS")}>
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0 }}
              disableRipple
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="16px"
              >
                <Box>
                  <Typography
                    sx={{
                      color: "#050415",
                      fontWeight: "700",
                      lineHeight: "22.4px",
                      letterSpacing: "0.48px",
                    }}
                  >
                    {`${user?.userMeta?.firstName ?? ""} ${
                      user?.userMeta?.lastName ?? ""
                    }`}
                  </Typography>
                </Box>
                <Avatar src={user?.image?.url} alt="Remy Sharp" />
              </Box>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{
              mt: "45px",
            }}
            PaperProps={{
              sx: {
                borderRadius: "10px",
                minWidth: "200px",
                padding: "8px 0",
              },
            }}
            MenuListProps={{
              sx: {
                padding: 0,
                direction: theme.direction,
              },
            }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {hasProfileAccess?.hasReadAccess && (
              <>
                <MenuItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                  onClick={() => navigate("/profile")}
                >
                  <PersonIcon />
                  <Typography>{t("SIDEBAR.PROFILE")}</Typography>
                </MenuItem>
                <Divider />
              </>
            )}
            <MenuItem
              onClick={signout}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ color: "#4596FF" }}>
                {t("PROFILE.LOG_OUT")}
              </Typography>
              <PowerSettingsNewIcon sx={{ color: "#4596FF" }} />
            </MenuItem>
          </Menu>
        </Box>
      </Grid2>
    </Grid2>
  );
}
