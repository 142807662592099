/**
 * Asynchronously loads the component for HomePage
 */

import { LoadingIndicator } from "app/components/LoadingIndicator";
import * as React from "react";
import { lazyLoad } from "utils/loadable";

export const LoginPage = lazyLoad(
  () => import("./index"),
  (module) => module.LoginPage,
  {
    fallback: (
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingIndicator />
      </div>
    ),
  }
);
