/**
 * Gets the repositories of the user from Player
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { request } from "utils/request";

import { LoggedInUser } from "app/containers/Auth/types";
import { toast } from "react-toastify";
import { Translate } from "utils/helpers";
import { actions } from "./slice";

export function* getMeApi(action: any) {
  try {
    const options = {
      method: "GET",
    };
    const url = `${API_URL}/v1/auth/me`;
    const response: LoggedInUser = yield call(request, url, options);
    yield put(actions.setUser(response));
  } catch (err: any) {
    const error = JSON.parse(err?.message)?.errors;
    toast(Translate(`COMMON.${error?.message ?? err?.message}`));
    yield put(actions.setUserCompleted());
    action?.payload?.callback();
    return;
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* permissionSaga() {
  yield takeLatest(actions.getUser.type, getMeApi);
}
