import { makeStyles } from "@mui/styles";

export const useLgStyles = makeStyles(() => ({
  root: {
    height: "100vh",
    width: "100%",
    position: "relative",
  },
  grid: {
    height: "100%",
    padding: "27px 20px",
    paddingTop: "27px"
  },
  contentWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  title: {
    color: "#050415",
    fontFamily: "Cairo",
    fontSize: "28px",
    fontWeight: 400,
    lineHeight: "39.2px",
    textAlign: "center",
    marginBottom: "53px"
  },
  link: {
      color: "#4596FF",
      textAlign: "center",
      fontFamily: "Open Sans",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "24px 0",
      cursor: "pointer"
  },
  btnText: {
    color: "#FFF",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 400,
  },
  blueGradiant: {
    width: "616px",
    height: "689px",
    flexShrink: 0,
    borderRadius: "689px",
    background: "#486ECD",
    filter: "blur(100px)",
    position: "absolute",
    top: "441px",
    left: "284px",
    zIndex: 1
  },
  greenGradiant: {
    width: "355px",
    height: "378px",
    flexShrink: 0,
    borderRadius: "378px",
    background: "#58BC7C",
    filter: "blur(100px)",
    position: "absolute",
    top: "688px",
    left: "167px",
    zIndex: 2
  },
  purpleGradiant: {
    width: "473px",
    height: "651px",
    flexShrink: 0,
    borderRadius: "651px",
    background: "rgba(114, 100, 228, 0.60)",
    filter: "blur(100px)",
    position: "absolute",
    top: "400px",
    left: "-171px",
  },
  phone: {
    width: "300px",
    height: "607.018px",
    flexShrink: 0,
    position: "absolute",
    top: "150px",
    left: "150px",
    zIndex: 3
  },
  cards: {
    width: "237px",
    height: "419.83px",
    top: "600px",
    left: "403px",
    position: "absolute",
    zIndex: 2
  },
  btnText2: {
    color: "#FFF",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 700,
  },
  forgetPassword: {
    color: "#050415",
    fontFamily: "Cairo",
    fontSize: "14px",
    fontWeight: 400,
    textDecorationLine: "underline",
    textAlign: "start",
    marginTop: "10px",
  },
  error: {
    color: "#FF0000",
    fontFamily: "Cairo",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "center",
    marginTop: "10px",
  }
}));
