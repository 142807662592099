import SearchIcon from "@mui/icons-material/Search";
import { Box, useTheme } from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import TextInput from "../TextInput";

export default function Search() {
  const theme = useTheme();
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  let delayTimer: NodeJS.Timeout | null = null; // To store the timer ID

  const [searchParams] = useSearchParams();

  const q = searchParams.get("q");

  const navigate = useNavigate();

  const onSubmit = () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (ref?.current?.value) {
      searchParams.set("q", ref?.current?.value);
      navigate(`${window.location.pathname}?${searchParams.toString()}`);
      return;
    }
    searchParams.delete("q");
    navigate(`${window.location.pathname}?${searchParams.toString()}`);
  };

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    // Clear the previous timer if any
    if (delayTimer) {
      clearTimeout(delayTimer);
    }

    // Set the new timer
    delayTimer = setTimeout(() => {
      onSubmit();
    }, 1000); // 1000 milliseconds = 1 second delay
  }

  return (
    <Box
      sx={{
        height: "100%",
        direction: theme.direction,
      }}
    >
      <TextInput
        inputRef={ref}
        placeholder={t("COMMON.SEARCH")}
        inputStyle={{
          height: "48px !important",
          fontSize: "16px !important",
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            onSubmit();
          }
        }}
        onChange={handleInputChange}
        defaultValue={q ?? ""}
        onBlur={onSubmit}
        endAdornment={
          <SearchIcon onClick={onSubmit} sx={{ color: "#4596FF" }} />
        }
      />
    </Box>
  );
}
