/**
 * Homepage selectors
 */

import { RootState } from "types";

import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// TODO: Add an explanation for this
const selectDomain = (state: RootState) =>
  state.globalFieldsState || initialState;

export const selectWaterCompany = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.waterCompany
);

export const selectCity = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.city
);

export const selectSite = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.site
);

export const selectSites = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.sites
);

export const selectWaterCompanies = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.waterCompanies
);

export const selectCities = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.cities
);

export const selectIsLoadingCities = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.isLoadingCities
);

export const selectIsLoadingSites = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.isLoadingSites
);

export const selectIsLoadingWaterCompanies = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.isLoadingWaterCompanies
);

export const selectIsLoading = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.isLoading
);

export const selectIsLoadingUserGroups = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.isLoadingUserGroups
);

export const selectUserGroups = createSelector(
  [selectDomain],
  (dashboardState) => dashboardState.userGroups
);
