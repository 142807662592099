/**
 * permission selectors
 */

import { createSelector } from "@reduxjs/toolkit";
import {
  PermissionKeys,
  PermissionLevel,
} from "app/containers/Permissions/type";
import { RootState } from "types";
import { initialState } from "./slice";

export const selectUserPermissions = (state: RootState) =>
  state?.globalPermissionState?.user?.userMeta?.permissionGroup
    ?.permissionCapabilities ||
  initialState?.user?.userMeta?.permissionGroup?.permissionCapabilities;
export const selectUserRole = (state: RootState) =>
  state?.globalPermissionState?.user?.role;

export const selectDomain = (state: RootState) =>
  state.globalPermissionState || initialState;

// Use like below in your component
// const capabilitiesForPermissions = useSelector(selectCapabilitiesForPermissions);
// // Access permission levels for specific permissions
// const dashboardViewStats = capabilitiesForPermissions[PermissionKeys.DASHBOARD_VIEW_STATS];
// const profileEditInfo = capabilitiesForPermissions[PermissionKeys.PROFILE_EDIT_INFO];

export const selectCapabilitiesForPermissions = createSelector(
  selectUserPermissions,
  selectUserRole,
  (capabilities, userRole) => {
    const isAdmin = userRole === "ADMIN";

    const permissions: Record<
      PermissionKeys,
      { hasNoAccess: boolean; hasReadAccess: boolean; hasFullAccess: boolean }
    > = {} as Record<
      PermissionKeys,
      { hasNoAccess: boolean; hasReadAccess: boolean; hasFullAccess: boolean }
    >;

    // Iterate through PermissionKeys enum values
    Object.values(PermissionKeys).forEach((permissionKey) => {
      const capability = capabilities?.find((cap) => {
        return cap.name === permissionKey;
      });

      const permission: {
        hasNoAccess: boolean;
        hasReadAccess: boolean;
        hasFullAccess: boolean;
      } = {
        hasNoAccess:
          (!isAdmin && !capability) ||
          ((capability &&
            capability.value === PermissionLevel.NO_ACCESS) as boolean),
        hasReadAccess:
          isAdmin ||
          ((capability &&
            capability.value === PermissionLevel.READ_ONLY) as boolean) ||
          ((capability &&
            capability.value === PermissionLevel.FULL_ACCESS) as boolean),
        hasFullAccess:
          isAdmin ||
          ((capability &&
            capability.value === PermissionLevel.FULL_ACCESS) as boolean),
      };

      permissions[permissionKey] = permission;
    });

    return permissions;
  }
);

export const selectIsAdmin = createSelector(selectUserRole, (userRole) => {
  return userRole === "ADMIN";
});

export const selectHasAccess = createSelector(
  selectCapabilitiesForPermissions,
  selectIsAdmin,
  (capabilities, isAdmin) => {
    if (isAdmin) return true;
    return Object.values(capabilities).some(
      (permission) => permission.hasReadAccess
    );
  }
);

export const selectIsInitializing = createSelector(selectDomain, (state) => {
  return state.isLoading;
});

export const selectUser = createSelector(selectDomain, (state) => {
  return state.user;
});

export const selectLanguage = createSelector(selectUser, (state) => {
  return state?.language;
});
