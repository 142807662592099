import { createSlice } from "utils/@reduxjs/toolkit";

import { PayloadAction } from "@reduxjs/toolkit";
import { QueryParams } from "app/containers/types";
import { ContainerState, HistoryState, StatItem, StatsType } from "../types";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  stats: null,
  isLoading: false,
  history: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalPages: 0,
      currentPage: 0,
    }
  } as unknown as HistoryState,
  isLoadingHistory: false,
};

const dashboardSlice = createSlice({
  name: "dashboardState",
  initialState,
  reducers: {
    getStats(state, action: PayloadAction<QueryParams>) {
      state.isLoading = true;
    },
    setStats(state, action: PayloadAction<StatsType>) {
      state.isLoading = false;
      state.stats = action.payload;
    },
    getHistory(state, action: PayloadAction<QueryParams>) {
      state.isLoadingHistory = true;
    },
    setHistory(state, action: PayloadAction<HistoryState>) {
      state.isLoadingHistory = false;
      state.history = action.payload;
    },
  },
});

export const { actions, reducer, name: dashboardSliceKey } = dashboardSlice;
