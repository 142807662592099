import { FormError } from "app/containers/types";

export function validate(form: {
  email: string;
  error: string;
}): Array<FormError> {
  const errors: Array<FormError> = [];
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(form.email)) {
    errors.push({
      name: "email",
      error: "COMMON.USERS.ERRORS.USER_NOT_FOUND",
    });
  }
  return errors;
}
