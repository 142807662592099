import { createSlice } from "utils/@reduxjs/toolkit";

import { PayloadAction } from "@reduxjs/toolkit";

import { LoggedInUser } from "app/containers/Auth/types";
import { ContainerState } from "./types";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  user: undefined,
  isLoading: false,
};
const permissionSlice = createSlice({
  name: "globalPermissionState",
  initialState,
  reducers: {
    getUser(state, action: PayloadAction<{ callback?: () => void }>) {
      state.isLoading = true;
    },
    setUser(state, action: PayloadAction<LoggedInUser>) {
      state.user = action.payload;
      state.isLoading = false;
    },
    setUserCompleted(state) {
      state.isLoading = false;
    },
  },
});

export const { actions, reducer, name: permissionSliceKey } = permissionSlice;
