import { Box, Typography } from '@mui/material'
import React from 'react'
import { Colors } from 'utils/constants'

interface KeyProps {
    color: "green" | "yellow" | "blue" | "grey" | "pink"
    label?: string
}

export default function Key({
    color,
    label
}:KeyProps) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px"
            }}
        >
            <Box 
                sx={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "5px",
                    background: Colors[color]
                }}
            />
            <Typography
                sx={{
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: 500,
                }}
            >
                {label}
            </Typography>
        </Box>
    )
}
