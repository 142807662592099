import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";
import React, { CSSProperties } from "react";
import { useStyles } from "./styles";

interface ButtonProps {
  children?: React.ReactNode | string;
  fullWidth?: boolean;
  btnStyle?: CSSProperties;
  onClick?: (e) => void;
  disabled?: boolean;
  isLoading?: boolean;
  loaderColor?: string;
  type?: "button" | "submit" | "reset" | undefined;
}

export default function CustomButton({
  children = "Button",
  fullWidth = true,
  btnStyle,
  onClick,
  disabled,
  isLoading,
  loaderColor = "#fff",
  type = "button",
}: ButtonProps) {
  const classes = useStyles();
  return (
    <LoadingButton
      onClick={onClick}
      className={classes.btn}
      fullWidth={fullWidth}
      sx={{
        background: disabled ? "#C6DCEE" : "#4596FF",
        "&:hover": {
          background: "#3676CC",
        },
      }}
      style={btnStyle}
      disabled={disabled}
      loading={isLoading}
      loadingPosition="center"
      type={type}
      loadingIndicator={
        <CircularProgress sx={{ color: loaderColor }} size={18} />
      }
    >
      {!isLoading && children}
    </LoadingButton>
  );
}
