/**
 * Homepage selectors
 */

import { RootState } from "types";

import { createSelector } from "@reduxjs/toolkit";

import { PermissionKeys } from "app/containers/Permissions/type";
import { selectCapabilitiesForPermissions } from "store/fields/permissions/selectors";
import { initialState } from "./slice";

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.userState || initialState;

export const selectFormValues = createSelector(
  [selectDomain],
  (userState) => userState.formValues
);

export const selectUsers = createSelector(
  [selectDomain],
  (userState) => userState.users
);

export const selectIsLoading = createSelector(
  [selectDomain],
  (userState) => userState.isLoading
);

export const selectIsSubmitting = createSelector(
  [selectDomain],
  (userState) => userState.isSubmitting
);

export const selectMetaData = createSelector(
  [selectDomain],
  (userState) => userState.metaData
);

export const selectHasUsersAccess = createSelector(
  [selectCapabilitiesForPermissions],
  (capabilitiesForPermissions) => {
    return (
      capabilitiesForPermissions[PermissionKeys.USER_ADD_USER]?.hasReadAccess ||
      capabilitiesForPermissions[PermissionKeys.USER_DELETE_USER]
        ?.hasReadAccess ||
      capabilitiesForPermissions[PermissionKeys.USER_EDIT_USER]?.hasReadAccess
    );
  }
);
