export enum StatusType {
  NEW,
  TAKEN_CARE_OF,
  IRRELEVANT,
  CLOSED,
  CANCELED,
}

export enum Roles {
  BACK_OFFICE = "BACK_OFFICE",
  ADMIN = "ADMIN",
}
