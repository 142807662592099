import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoadingSites,
  selectSite,
  selectSites,
  selectWaterCompany,
} from "store/fields/global/selectors";
import { actions } from "store/fields/global/slice";
import { removeBlankProperties } from "utils/helpers";
import AsyncSelect from "../AsyncSelect";

export default function SiteSelect() {
  const site = useSelector(selectSite);
  const waterCompanyId = useSelector(selectWaterCompany)?.value;
  const isLoading = useSelector(selectIsLoadingSites);
  const sites = useSelector(selectSites);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      actions.setSite({
        label: "All",
        value: "all",
      })
    );
    dispatch(
      actions.getSites(
        removeBlankProperties({
          limit: 200,
          waterCompanyId: waterCompanyId === "all" ? undefined : waterCompanyId,
        })
      )
    );
  }, [dispatch, waterCompanyId]);

  return (
    <AsyncSelect
      labelStyle={{
        marginLeft: 0,
      }}
      label={t("COMMON.SITE")}
      inputStyle={{
        borderColor: "#EFF0F6",
      }}
      value={site}
      options={[
        {
          label: "All",
          value: "all",
        },
        ...sites?.map((item) => ({
          label: item.siteName,
          value: item.siteId?.toString(),
        })),
      ]}
      defaultValue={{
        label: "All",
        value: "all",
      }}
      onBlur={(e) => {
        if (!e.target.value) {
          dispatch(
            actions.setSite({
              label: "All",
              value: "all",
            })
          );
        }
      }}
      loading={isLoading}
      loadingText={t("COMMON.LOADING")}
      noOptionsText={t("COMMON.NO_OPTIONS")}
      onChange={(e, val) => {
        dispatch(
          actions.setSite(
            val ?? {
              label: "",
              value: "",
            }
          )
        );
      }}
    />
  );
}
