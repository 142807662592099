import { LoginResponse } from "types/LoginResponse";
import { createSlice } from "utils/@reduxjs/toolkit";

import { createAction, PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import { FormError } from "../../types";
import { ContainerState, LoggedInUser } from "../types";

export const logoutSuccess = createAction("LOGOUT_SUCCESS");
// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  email: "",
  password: "",
  loginForm: {
    email: {
      value: "",
    },
    password: {
      value: "",
    },
  },
  loading: false,
  error: null,
  token: localStorage.getItem("sessionToken") || "",
  role: localStorage.getItem("role") || "",
  forgotPasswordForm: {
    email: "",
    error: "",
  },
  loggedInUser: null,
  resetForm: {
    password: {
      value: "",
      error: "",
    },
    confirm_password: {
      value: "",
      error: "",
    },
  },
};

const authSlice = createSlice({
  name: "authState",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(logoutSuccess, (state) => {
      state.token = "";
    });
  },
  reducers: {
    setForm: (state, action: PayloadAction<{ key: string; value: string }>) => {
      const { key, value } = action.payload;
      set(state.loginForm, `${key}.value`, value);
      set(state.loginForm, `${key}.error`, "");
    },
    setFormErrors(state, action: PayloadAction<Array<FormError>>) {
      action.payload.forEach((error) => {
        set(state.loginForm, `${error.name}.error`, error.error);
      });
    },
    changeEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
      state.error = null;
    },
    changePassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
      state.error = null;
    },
    logout(state) {},
    login(
      state,
      action: PayloadAction<{
        email: string;
        password: string;
        callback: () => void;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    forgotPassword(
      state,
      action: PayloadAction<{ email; callback: () => void }>
    ) {
      state.loading = true;
    },
    loginSuccess(state, action: PayloadAction<LoginResponse>) {
      state.email = "";
      state.password = "";
      state.loading = false;
      state.error = null;
      state.token = action.payload.token;
      state.role = action.payload.role;
    },
    loginError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    setError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    setForgotError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.forgotPasswordForm.error = action.payload;
    },
    setForgotEmail(state, action: PayloadAction<string>) {
      state.forgotPasswordForm.email = action.payload;
      state.forgotPasswordForm.error = "";
    },
    forgotPasswordSuccess(state) {
      state.loading = false;
    },
    resetError: (state) => {
      state.forgotPasswordForm.error = "";
    },
    resetEmail: (state) => {
      state.forgotPasswordForm.email = "";
    },
    updateResetForm: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      state.resetForm[action.payload.name].value = action.payload.value;
      state.resetForm[action.payload.name].error = "";
    },
    updateResetFormError: (state, action: PayloadAction<Array<FormError>>) => {
      action.payload.forEach((error) => {
        state.resetForm[error.name].error = error.error;
      });
    },
    resetPassword: (
      state,
      action: PayloadAction<{
        token: string;
        email: string;
        password: string;
        callback: () => void;
      }>
    ) => {
      state.loading = true;
    },
    resetPasswordSuccess: (state) => {
      state.loading = false;
      state.resetForm = {
        password: {
          value: "",
          error: "",
        },
        confirm_password: {
          value: "",
          error: "",
        },
      };
    },
    getUser: (state, action: PayloadAction<{ callback?: () => void }>) => {
      state.loading = true;
    },
    getUserSuccess: (state, action: PayloadAction<LoggedInUser>) => {
      state.loading = false;
      state.loggedInUser = action.payload;
    },
    getUserError: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = "Error";
    },
  },
});

export const { actions, reducer, name: authSliceKey } = authSlice;
