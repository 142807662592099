import { UserType } from "../Users/types";
import { FormElement, Meta } from "../types";

export interface PermissionsState {
  query: {
    q?: string 
  }
  userGroups: UserGroups[];
  capabilities: CapabilitiesType[];
  isLoading: boolean;
  meta: Meta;
  groupName: FormElement;
  isSubmitting: boolean;
  users: UserType[];
  userMeta: Meta;
  isLoadingUsers: boolean;
}

export interface UserGroups {
  id: number;
  name: "Admin";
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  usersCount: string;
}

export const PermissionLevel = {
  NO_ACCESS: 1,
  READ_ONLY: 2,
  FULL_ACCESS: 3,
};

export type PermissionLevelValues = 1 | 2 | 3;

export enum PermissionKeys {
  DASHBOARD_VIEW_STATS = "DASHBOARD.VIEW_STATS",
  DASHBOARD_VIEW_STAT_HISTORY = "DASHBOARD.VIEW_STAT_HISTORY",
  PROFILE_EDIT_INFO = "PROFILE.EDIT_INFO",
  USER_DELETE_USER = "USER.DELETE_USER",
  USER_ADD_USER = "USER.ADD_USER",
  USER_EDIT_USER = "USER.EDIT_USER",
  CONSUMER_SEND_MESSAGE = "CONSUMER.SEND_MESSAGE",
  CONSUMER_EDIT_INFO = "CONSUMER.EDIT_INFO",
}

export type PermissionForm = {section: string; localSection:  string;  features: {
  name: string;
  localName: string;
  value: number;
  category: string; }[]
}
export interface PermissionRowType {
  section: string;
  name: string
  features: {
    featureName: PermissionKeys;
    noAccess: boolean;
    readOnly: boolean;
    fullAccess: boolean;
  }[];
}

export interface CapabilitiesType {
  name: string;
  value: number;
  category: string;
}

export type ContainerState = PermissionsState;
