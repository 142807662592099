/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import ScrollToTop from "app/components/ScrollToTop";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { globalFieldsSaga } from "store/fields/global/saga";
import {
  reducer as globalFieldsReducer,
  globalFieldsSliceKey,
} from "store/fields/global/slice";
import { permissionSaga } from "store/fields/permissions/saga";
import {
  reducer as permissionReducer,
  permissionSliceKey,
} from "store/fields/permissions/slice";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { selectDirection, themeActions } from "../styles/theme/slice";
import { AuthPage } from "./containers/Auth";
import ChangePassword from "./containers/Auth/Containers/ChangePassword/form";
import CreatePassword from "./containers/Auth/Containers/CreatePassword/form";
import ForgotPassword from "./containers/Auth/Containers/ForgotPassword/form";
import LoginForm from "./containers/Auth/Containers/LoginPage/form";
import SignupForm from "./containers/Auth/Containers/SignupPage/form";
import { authSaga } from "./containers/Auth/redux/saga";
import {
  reducer as authReducer,
  authSliceKey,
} from "./containers/Auth/redux/slice";
import ConsumersLoadable from "./containers/Consumers/Loadable";
import DashboardRoutes from "./containers/Dashboard/routes";
import NoAccessLoadable from "./containers/NoAccess/Loadable";
import PermissionsLoadable from "./containers/Permissions/Loadable";
import ProfileLoadable from "./containers/Profile/Loadable";
import SettingsLoadable from "./containers/Settings/Loadable";
import { UnsupportedScreen } from "./containers/UnsupportedScreen";
import UserLoadable from "./containers/Users/Loadable";
import PageLoadable from "./containers/pages/Loadable";

const MIN_WIDTH = 1024;

export function App() {
  useInjectSaga({ key: authSliceKey, saga: authSaga });
  useInjectReducer({ key: authSliceKey, reducer: authReducer });
  useInjectSaga({ key: globalFieldsSliceKey, saga: globalFieldsSaga });
  useInjectReducer({ key: globalFieldsSliceKey, reducer: globalFieldsReducer });
  useInjectSaga({ key: permissionSliceKey, saga: permissionSaga });
  useInjectReducer({ key: permissionSliceKey, reducer: permissionReducer });

  const { width } = useWindowDimensions();

  const unsupportedScreen = width && width <= MIN_WIDTH;
  const direction = useSelector(selectDirection);
  const dispatch = useDispatch();

  const selectedLanguage = useMemo(() => {
    return {
      language: localStorage.getItem("i18nextLng"),
      direction,
    };
  }, [direction]);

  const toggleDirection = (lang: "en" | "he") => {
    dispatch(themeActions.toggleDirection(lang));
  };

  useEffect(() => {
    if (selectedLanguage) {
      if (
        selectedLanguage.language === "he" &&
        selectedLanguage.direction !== "rtl"
      ) {
        toggleDirection(selectedLanguage.language);
      }
      if (
        selectedLanguage.language === "en" &&
        selectedLanguage.direction !== "ltr"
      ) {
        toggleDirection(selectedLanguage.language);
      }
    }
  }, [selectedLanguage]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Helmet titleTemplate="%s - Afcon" defaultTitle="Afcon Admin">
        <meta name="description" content="A Afcon Admin" />
      </Helmet>
      <div dir={direction}>
        <Routes>
          <Route path="/pages/*" element={<PageLoadable />} />
        </Routes>
        {unsupportedScreen ? (
          <UnsupportedScreen minWidth={MIN_WIDTH} />
        ) : (
          <Routes>
            <Route path="/*" element={<DashboardRoutes />} />
            <Route path="/users/*" element={<UserLoadable />} />
            <Route path="/profile/*" element={<ProfileLoadable />} />
            <Route path="/settings/*" element={<SettingsLoadable />} />
            <Route path="/no-access/*" element={<NoAccessLoadable />} />
            <Route path="/permissions/*" element={<PermissionsLoadable />} />
            <Route path="/consumers/*" element={<ConsumersLoadable />} />

            <Route path="/auth/*" element={<AuthPage />}>
              <Route path="login" index element={<LoginForm className="" />} />
              <Route path="signup" element={<SignupForm className="" />} />
              <Route
                path="forgot-password"
                element={<ForgotPassword className="" />}
              />
              <Route
                path="reset-password"
                element={<ChangePassword className="" />}
              />
              <Route
                path="create-password"
                element={<CreatePassword className="" />}
              />
            </Route>
          </Routes>
        )}
      </div>
    </BrowserRouter>
  );
}
