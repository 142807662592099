import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Layout from "app/components/Layout";
import PageLoader from "app/components/PageLoader";
import Barchart from "app/containers/Dashboard/components/Barchart";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectSite, selectWaterCompany } from "store/fields/global/selectors";
import { selectCapabilitiesForPermissions } from "store/fields/permissions/selectors";
import { removeBlankProperties } from "utils/helpers";
import { PermissionKeys } from "../Permissions/type";
import Header from "./components/Header";
import RingChart from "./components/RingChart";
import {
  selectDailyStats,
  selectHistoryStats,
  selectIsLoading,
  selectMonthlyStats,
} from "./redux/selectors";
import { actions } from "./redux/slice";

export default function Dashboard() {
  const { t } = useTranslation();
  const isLoading = useSelector(selectIsLoading);
  const dailyStats = useSelector(selectDailyStats);
  const monthlyStats = useSelector(selectMonthlyStats);
  const history = useSelector(selectHistoryStats);
  const waterCompanyId = useSelector(selectWaterCompany)?.value;
  const capabilitiesForPermissions = useSelector(
    selectCapabilitiesForPermissions
  );
  const siteId = useSelector(selectSite)?.value;
  const dispatch = useDispatch();

  const canViewStats =
    capabilitiesForPermissions[PermissionKeys.DASHBOARD_VIEW_STATS];

  const canViewStatHistory =
    capabilitiesForPermissions[PermissionKeys.DASHBOARD_VIEW_STAT_HISTORY];

  useEffect(() => {
    if (!localStorage.getItem("sessionToken")) {
      return;
    }
    dispatch(
      actions.getStats(
        removeBlankProperties({
          waterCompanyId: waterCompanyId === "all" ? undefined : waterCompanyId,
          siteId: siteId === "all" ? undefined : siteId,
        })
      )
    );
    dispatch(
      actions.getHistory(
        removeBlankProperties({
          waterCompanyId: waterCompanyId === "all" ? undefined : waterCompanyId,
          siteId: siteId === "all" ? undefined : siteId,
        })
      )
    );
  }, [dispatch, waterCompanyId, siteId]);

  return (
    <Layout>
      <Box>
        <Header />
        <Box>
          {isLoading && <PageLoader />}
          {canViewStats.hasReadAccess && (
            <Grid2 spacing={3} sx={{ marginBottom: "40px" }} container>
              <RingChart
                data={dailyStats}
                title={t(`DASHBOARD.WATCHED_DAILY_ACTIVITY`)}
              />
              <RingChart
                data={monthlyStats}
                title={t(`DASHBOARD.WATCHED_MONTHLY_ACTIVITY`)}
              />
            </Grid2>
          )}
          {canViewStatHistory.hasReadAccess && <Barchart history={history} />}
        </Box>
      </Box>
    </Layout>
  );
}
