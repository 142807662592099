import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: "#000",
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "10px",
    marginLeft: "16px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    display: "block",
  },
  input: {
    borderRadius: "20px",
    border: "1px solid #C1C6CB",
    fontFamily: "Cairo",
    fontSize: "16px",
    padding: "0 18px",
    fontWeight: 400,
    height: "55px",
    "&:hover": {
      borderColor: "#000",
    },
    "&:focus": {
      border: "1px solid #4596FF !important",
    },
    "&::placeholder": {
      color: "#999",
      fontFamily: "Cairo",
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  error: {
    color: "#FF0000",
    fontSize: "14px",
    fontWeight: 400,
  },
  required: {
    color: "#FF0000",
    fontWeight: 800,
  },
}));
