import { createSlice } from "utils/@reduxjs/toolkit";

import { PayloadAction } from "@reduxjs/toolkit";
import { FormError, Meta, QueryParams } from "app/containers/types";
import set from "lodash/set";
import { ContainerState, UserType } from "../types";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  formValues: {
    firstName: {
      value: "",
      error: "",
    },
    lastName: {
      value: "",
      error: "",
    },
    email: {
      value: "",
      error: "",
    },
    phoneNumber: {
      value: "",
      error: "",
    },
    userGroup: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    waterCompany: {
      value: "",
      error: "",
    },
  },
  users: [],
  metaData: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 1,
  },
  isLoading: false,
  isSubmitting: false,
};

const userSlice = createSlice({
  name: "userState",
  initialState,
  reducers: {
    setFormValues(
      state,
      action: PayloadAction<{ key: string; value: string }>
    ) {
      const { key, value } = action.payload;
      set(state.formValues, `${key}.value`, value);
      set(state.formValues, `${key}.error`, "");
    },
    setFormErrors(state, action: PayloadAction<Array<FormError>>) {
      action.payload.forEach((error) => {
        set(state.formValues, `${error.name}.error`, error.error);
      });
    },
    resetFormValues(state) {
      state.formValues = initialState.formValues;
    },
    getUsers(state, action: PayloadAction<QueryParams>) {
      state.isLoading = true;
    },
    setUsers(
      state,
      action: PayloadAction<{ users: UserType[]; metaData: Meta }>
    ) {
      const users =
        action.payload?.metaData?.currentPage > 1
          ? [...state.users, ...action.payload.users]
          : action.payload.users;
      state.users = users;
      state.metaData = action.payload?.metaData;
      state.isLoading = false;
    },
    updateUsersPage(state, action: PayloadAction<number>) {
      state.metaData.currentPage = action.payload;
    },
    setUsersError(state) {
      state.isLoading = false;
    },
    addUser: (state, action: PayloadAction<UserType>) => {
      state.isSubmitting = true;
    },
    updateUser: (state, action: PayloadAction<UserType>) => {
      state.isSubmitting = true;
    },
    addUserSuccess: (state, action: PayloadAction<UserType>) => {
      state.isSubmitting = false;
    },
    addUserError: (state) => {
      state.isSubmitting = false;
    },
    createUser(state, action: PayloadAction<any>) {
      state.isSubmitting = true;
    },
    deleteUser(
      state,
      action: PayloadAction<{ id: string; callback: () => void }>
    ) {
      state.isSubmitting = true;
    },
    deleteUserComplete(state) {
      state.isSubmitting = false;
    },
  },
});

export const { actions, reducer, name: userSliceKey } = userSlice;
