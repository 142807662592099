import { Box, Typography, useTheme } from "@mui/material";
import CustomButton from "app/components/Button";
import TextInput from "app/components/TextInput";
import RegisterUserSuccess from "app/containers/Users/components/Success";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectForgotPasswordForm, selectLoading } from "../../redux/selectors";
import { actions } from "../../redux/slice";
import { useLgStyles } from "../LoginPage/styles";
import { validate } from "./validator";

export function ForgotPassword({ className }) {
  const classes = useLgStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const formValues = useSelector(selectForgotPasswordForm);
  const isLoading = useSelector(selectLoading);
  const [open, setOpen] = React.useState(false);

  const onSubmit = (e) => {
    e?.preventDefault();
    const errors = validate(formValues);
    if (errors?.length > 0) {
      dispatch(actions.setForgotError(errors[0]?.error));
      return;
    }
    dispatch(
      actions.forgotPassword({
        email: formValues.email,
        callback: () => setOpen(true),
      })
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        padding: "0 20%",
        direction: theme.direction,
      }}
    >
      <form>
        <Typography className={classes.title}>
          {t("AUTH.FORGOT_PASSWORD_TITLE")}
        </Typography>
        <TextInput
          placeholder={t("AUTH.EMAIL")}
          containerStyle={{
            width: "100%",
            marginBottom: "9px",
          }}
          type="email"
          value={formValues.email}
          onChange={(e) => dispatch(actions.setForgotEmail(e.target.value))}
          error={formValues.error && t(`${formValues.error}`)}
          disabled={isLoading}
        />
        <CustomButton
          onClick={onSubmit}
          disabled={isLoading || !formValues.email}
          btnStyle={{
            marginTop: "52px",
          }}
          type="submit"
          isLoading={isLoading}
        >
          <Typography className={classes.btnText}>
            {t("BUTTONS.CONTINUE")}
          </Typography>
        </CustomButton>
        <RegisterUserSuccess
          open={open}
          handleClose={() => {
            setOpen(false);
            navigate("/auth/login");
          }}
        />
      </form>
    </Box>
  );
}

export default ForgotPassword;
