
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { LoginPage } from './Containers/LoginPage/Loadable';

export function AuthPage() {
  const auth = localStorage.getItem('sessionToken');
  if(auth){
    return <Navigate to="/" replace />
  }
  return (
    <>
      <LoginPage />
    </>
  );
}
