import { Box, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import { useStyles } from "./styles";

interface Props {
  label?: string;
  labelStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  error?: string;
  options?: {
    label: string;
    value: string;
  }[];
  inputStyle?: React.CSSProperties;
  value?: {
    label: string;
    value: string;
  };
  disabled?: boolean;
  onChange?: (
    e: React.SyntheticEvent<Element, Event>,
    value: {
      label: string;
      value: string;
    } | null
  ) => void;
  loading?: boolean;
  loadingText?: string;
  noOptionsText?: string;
  fullWidth?: boolean;
  required?: boolean;
  onBlur?: (e) => void;
  defaultValue?: {
    label: string;
    value: string;
  };
}

export default function AsyncSelect({
  label,
  labelStyle,
  containerStyle,
  options = [],
  value,
  onChange,
  inputStyle,
  error,
  loading,
  loadingText,
  noOptionsText,
  fullWidth = true,
  disabled,
  required = false,
  onBlur,
  defaultValue,
}: Props) {
  const classes = useStyles();
  return (
    <Box
      style={{
        width: "100%",
        ...containerStyle,
      }}
    >
      {label && (
        <Typography style={labelStyle} className={classes.label}>
          {label}
          {required && <span className={classes.required}>*</span>}
        </Typography>
      )}
      <Autocomplete
        disabled={disabled}
        fullWidth={fullWidth}
        options={options}
        onChange={(event, newValue) => {
          onChange?.(event, newValue);
        }}
        onBlur={onBlur}
        loadingText={loadingText}
        noOptionsText={noOptionsText}
        loading={loading}
        getOptionLabel={(option) => option.label}
        value={value}
        defaultValue={defaultValue}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              sx: {
                borderRadius: "20px",
              },
              disableUnderline: true,
            }}
          />
        )}
      />

      {error && (
        <Typography
          component="small"
          sx={{
            color: "#FF0000",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
}
