import { FormError } from "app/containers/types";

export function validate(formValues: {
  password: { value: string };
  confirm_password: { value: string };
}): Array<FormError> {
  const errors: Array<FormError> = [];

  if (formValues?.password?.value?.length < 8) {
    errors.push({
      name: "password",
      error: "COMMON.USERS.ERRORS.PASSWORD_LENGTH",
    });
  }

  if (formValues?.confirm_password?.value?.length < 8) {
    errors.push({
      name: "confirm_password",
      error: "COMMON.USERS.ERRORS.PASSWORD_LENGTH",
    });
  }

  if (formValues?.password?.value !== formValues?.confirm_password?.value) {
    errors.push({
      name: "confirm_password",
      error: "COMMON.USERS.ERRORS.PASSWORD_MISMATCH",
    });
  }

  return errors;
}
