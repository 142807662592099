import React from "react";

import LogoImage from "assets/afcon_logo.png";

export const Logo = ({
  width = "300px",
  height = "199px",
}) => {
  return (
    <>
      <img src={LogoImage} width={width} height={height} alt="logo" />
    </>
  );
};
