/**
 * Gets the repositories of the user from Player
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { request } from "utils/request";

import { toast } from "react-toastify";
import { Translate } from "utils/helpers";
import { actions } from "./slice";

export function* getUsersGroupsApi(action: any) {
  try {
    const options = {
      method: "GET",
    };
    const query = new URLSearchParams(action.payload).toString();
    const url = `${API_URL}/v1/permission-groups?${query}`;
    const response = yield call(request, url, options);
    yield put(
      actions.setUserGroups({
        groups: response?.items,
      })
    );
  } catch (err: any) {
    const error = JSON.parse(err?.message)?.errors;
    yield put(actions.setUserGroupsError());
    toast(Translate(`COMMON.${error?.message ?? err?.message}`));
    return;
  }
}

export function* getWaterCompaniesApi(action: any) {
  try {
    const options = {
      method: "GET",
    };
    const query = new URLSearchParams(action.payload).toString();
    const url = `${API_URL}/v1/water-companies/dropdown?${query}`;
    const response = yield call(request, url, options);
    yield put(actions.setWaterCompanies(response?.items));
  } catch (err: any) {
    const error = JSON.parse(err?.message)?.errors;
    toast(Translate(`COMMON.${error?.message ?? err?.message}`));
    yield put(actions.getWaterCompanyComplete());
    return;
  }
}

export function* getCitiesApi() {
  try {
    const options = {
      method: "GET",
    };
    const url = `${API_URL}/v1/address/cities`;
    const response = yield call(request, url, options);
    yield put(actions.setCities(response?.items));
  } catch (err: any) {
    const error = JSON.parse(err?.message)?.errors;
    toast(Translate(`COMMON.${error?.message ?? err?.message}`));
    return;
  }
}

export function* getSitesApi(action: any) {
  try {
    const options = {
      method: "GET",
    };
    const query = new URLSearchParams(action.payload).toString();
    const url = `${API_URL}/v1/address/sites?${query}`;
    const response = yield call(request, url, options);
    yield put(actions.setSites(response?.items));
  } catch (err: any) {
    const error = JSON.parse(err?.message)?.errors;
    toast(Translate(`COMMON.${error?.message ?? err?.message}`));
    return;
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export function* globalFieldsSaga() {
  yield takeLatest(actions.getWaterCompanies.type, getWaterCompaniesApi);
  yield takeLatest(actions.getCities.type, getCitiesApi);
  yield takeLatest(actions.getSites.type, getSitesApi);
  yield takeLatest(actions.getUserGroups.type, getUsersGroupsApi);
}
