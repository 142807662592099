/**
 * Gets the repositories of the user from Player
 */

import { call, delay, put, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { request } from "utils/request";

import { toast } from "react-toastify";
import { Translate } from "utils/helpers";
import { actions } from "./slice";
import { HistoryState } from "../types";

export function* getStatsApi(action: any) {
  yield delay(200)
  try {
    const options = {
      method: "GET",
    };
    const query = new URLSearchParams(action.payload).toString();
    const url = `${API_URL}/v1/dashboard/stats?${query}`;
    const response = yield call(request, url, options);
    yield put(actions.setStats(response));
  } catch (err: any) {
    const error = JSON.parse(err?.message)?.errors;
    toast(Translate(`COMMON.${error?.message ?? err?.message}`));
    return;
  }
}

export function* getHistoryApi(action: any) {
  yield delay(200)
  try {
    const options = {
      method: "GET",
    };
    const query = new URLSearchParams(action.payload).toString();
    const url = `${API_URL}/v1/dashboard/history?${query}`;
    const response: HistoryState = yield call(request, url, options);
    yield put(actions.setHistory(response));
  } catch (err: any) {
    const error = JSON.parse(err?.message)?.errors;
    toast(Translate(`COMMON.${error?.message ?? err?.message}`));
    return;
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export function* dashboardSaga() {
  yield takeLatest(actions.getStats.type, getStatsApi);
  yield takeLatest(actions.getHistory.type, getHistoryApi);
}
