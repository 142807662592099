/**
 * Homepage selectors
 */

import { RootState } from "types";

import { createSelector } from "@reduxjs/toolkit";

import { PermissionKeys } from "app/containers/Permissions/type";
import { selectCapabilitiesForPermissions } from "store/fields/permissions/selectors";
import { initialState } from "./slice";

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.consumerState || initialState;

export const selectConsumers = createSelector(
  [selectDomain],
  (consumerState) => consumerState.consumers
);

export const selectMetaData = createSelector(
  [selectDomain],
  (consumerState) => consumerState.metaData
);

export const selectIsLoading = createSelector(
  [selectDomain],
  (consumerState) => consumerState.isLoading
);

export const selectConsumersIds = createSelector(
  [selectDomain],
  (consumerState) => consumerState.selectedConsumerIds
);

export const selectSelectedAll = createSelector(
  [selectDomain],
  (consumerState) => consumerState.selectedAll
);

export const selectFormValues = createSelector(
  [selectDomain],
  (consumerState) => consumerState.formValues
);

export const selectMessage = createSelector(
  [selectDomain],
  (consumerState) => consumerState.messageValues?.message?.value
);

export const selectSubject = createSelector(
  [selectDomain],
  (consumerState) => consumerState.messageValues?.subject?.value
);

export const selectMessageError = createSelector(
  [selectDomain],
  (consumerState) => consumerState.messageValues?.message?.error
);

export const selectSubjectError = createSelector(
  [selectDomain],
  (consumerState) => consumerState.messageValues?.subject?.error
);

export const selectIsSubmitting = createSelector(
  [selectDomain],
  (consumerState) => consumerState.isSubmitting
);

export const selectHasConsumersAccess = createSelector(
  [selectCapabilitiesForPermissions],
  (capabilitiesForPermissions) => {
    return (
      capabilitiesForPermissions[PermissionKeys.CONSUMER_EDIT_INFO]
        ?.hasReadAccess ||
      capabilitiesForPermissions[PermissionKeys.CONSUMER_SEND_MESSAGE]
        ?.hasReadAccess
    );
  }
);
