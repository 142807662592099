import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    children?: React.ReactNode;
    size?: 'sm' | 'md' | 'lg';
}

export default function Modal({
    open,
    handleClose,
    children,
    size
}:ModalProps) {
    return (
        <Dialog
            fullWidth
            maxWidth={size}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            PaperProps={{
                sx: {
                    background: "#FFF",
                    padding: "16px 37px",
                    position: "relative",
                    borderWidth: 2,
                    borderColor: "#4596FF",
                    borderRadius: "20px"
                }
            }}
        >
           {children}
        </Dialog>
    )
}
