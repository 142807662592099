import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionIcon from "@mui/icons-material/Description";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { selectHasConsumersAccess } from "app/containers/Consumers/redux/selectors";
import { selectHasDashboardAccess } from "app/containers/Dashboard/redux/selectors";
import { selectHasUsersAccess } from "app/containers/Users/redux/selectors";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectIsAdmin } from "store/fields/permissions/selectors";
import { Logo } from "../Logo";

const LINKS = [
  {
    name: "DASHBOARD",
    link: "/",
    icon: <DashboardIcon />,
  },
  {
    name: "PERMISSIONS",
    link: "/permissions",
    icon: <DescriptionIcon />,
  },
  {
    name: "USERS",
    link: "/users",
    icon: <GroupIcon />,
  },
  {
    name: "CONSUMERS",
    link: "/consumers",
    icon: <FactCheckOutlinedIcon />,
  },
  {
    name: "ADMIN_SETTINGS",
    link: "/settings",
    icon: <SettingsIcon />,
  },
];

export default function Sidebar() {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const { t } = useTranslation();
  const isAdmin = useSelector(selectIsAdmin);
  const hasDashboardAccess = useSelector(selectHasDashboardAccess);
  const hasUsersAccess = useSelector(selectHasUsersAccess);
  const hasConsumersAccess = useSelector(selectHasConsumersAccess);

  const isActive = (link: string) => {
    if (pathname === "/") return pathname === link;
    const newPathname = pathname?.replace("/", "");
    const newLink = link?.replace("/", "");
    if (!newPathname || !newLink) return false;
    return newPathname.startsWith(newLink);
  };

  const theme = useTheme();

  return (
    <Box
      sx={{
        borderRadius:
          theme.direction === "ltr" ? "0px 50px 50px 0px" : "50px 0px 0px 50px",
        background: "#4596FF",
        boxShadow: "2px 0px 3px 0px rgba(198, 201, 202, 0.40)",
        height: "100%",
        minWidth: "250px",
      }}
    >
      <Box
        sx={{
          height: "95px",
          borderRadius:
            theme?.direction === "ltr"
              ? "0px 0px 0px 50px"
              : "0px 0px 50px 0px",
          background: "#FFF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "14px 19px",
          marginBottom: "37px",
        }}
      >
        <Logo width="100px" height="66.33px" />
      </Box>

      <List
        sx={{
          padding: "0px 19px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          direction: theme.direction,
        }}
      >
        {LINKS.map((link, index) => {
          if (link.name === "DASHBOARD" && !hasDashboardAccess) return null;
          if (link.name === "PERMISSIONS" && !isAdmin) return null;
          if (link.name === "ADMIN_SETTINGS" && !isAdmin) return null;
          if (link.name === "USERS" && !hasUsersAccess) return null;
          if (link.name === "CONSUMERS" && !hasConsumersAccess) return null;
          return (
            <ListItem
              key={index}
              sx={{
                padding: "0px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ListItemButton
                onClick={() => navigate(link.link)}
                sx={{
                  padding: "8px 16px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "22px",
                  gap: "16px",
                  "&.Mui-selected": {
                    background: "#fefefe",
                    color: "#4596FF",
                  },
                  "&.Mui-selected:hover": {
                    background: "#fefefe",
                    color: "#4596FF",
                    opacity: 0.7,
                  },
                }}
                selected={isActive(link.link)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "unset",
                    marginRight: "16px",
                    width: "24px",
                    height: "24px",
                    color: isActive(link.link) ? "#4596FF" : "#fefefe",
                  }}
                >
                  {link.icon}
                </ListItemIcon>
                <ListItemText
                  primary={`${t(`SIDEBAR.${link.name}`)} `}
                  sx={{
                    fontSize: "14px",
                    lineHeight: "19.6px",
                    letterSpacing: "0.42px",
                    color: isActive(link.link) ? "#4596FF" : "#fefefe",
                    textAlign: theme.direction === "rtl" ? "right" : "left",
                  }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
