import { Box, Input, Typography, useTheme } from "@mui/material";
import React, { CSSProperties } from "react";
import { useStyles } from "./styles";

interface TextInputProps {
  label?: string;
  placeholder: string;
  containerStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean;
  error?: string;
  type?:
    | "text"
    | "password"
    | "email"
    | "number"
    | "search"
    | "tel"
    | "url"
    | "date"
    | "time"
    | "datetime-local"
    | "month"
    | "week";
  multiline?: boolean;
  rows?: number;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  sx?: CSSProperties;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  inputRef?: React.Ref<HTMLInputElement>;
  defaultValue?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  maxLength?: number;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  required?: boolean;
}

export default function TextInput({
  label,
  placeholder,
  containerStyle,
  inputStyle,
  labelStyle,
  name,
  onChange,
  value,
  disabled,
  error,
  type = "text",
  multiline,
  rows,
  startAdornment,
  endAdornment,
  onKeyPress,
  inputRef,
  onBlur,
  defaultValue,
  maxLength,
  onPaste,
  required = true,
}: TextInputProps) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box
      style={containerStyle}
      sx={{
        direction: theme.direction,
      }}
    >
      {label && (
        <Typography style={labelStyle} className={classes.label}>
          {label}
          {required && <span className={classes.required}>*</span>}
        </Typography>
      )}
      <Input
        inputProps={{
          ref: inputRef,
          maxLength: maxLength,
          sx:
            type === "number" || type === "email"
              ? {
                  textAlign: theme.direction === "ltr" ? "start" : "end",
                  direction: "ltr",
                }
              : {},
        }}
        onPaste={onPaste}
        defaultValue={defaultValue}
        onBlur={onBlur}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        className={classes.input}
        fullWidth
        placeholder={placeholder}
        disableUnderline
        onKeyPress={onKeyPress}
        sx={{
          ...inputStyle,
          ...(error && { border: "1px solid #FF0000 !important" }),
        }}
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
        type={type}
        multiline={multiline}
        rows={rows}
      />
      {error && (
        <Typography component="small" className={classes.error}>
          {error}
        </Typography>
      )}
    </Box>
  );
}
