import { createSlice } from "utils/@reduxjs/toolkit";

import { PayloadAction } from "@reduxjs/toolkit";
import { QueryParams } from "app/containers/types";
import set from "lodash/set";
import { UserGroups } from "../permissions/types";
import { CityType, ContainerState, StateType, WaterCompanyType } from "./types";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  waterCompany: {
    label: "All",
    value: "all",
  },
  city: {
    label: "All",
    value: "all",
  },
  site: {
    label: "All",
    value: "all",
  },
  waterCompanies: [],
  cities: [],
  sites: [],
  userGroups: [],
  isLoading: false,
  isLoadingCities: false,
  isLoadingWaterCompanies: false,
  isLoadingSites: false,
  isLoadingUserGroups: false,
};

const globalFieldsSlice = createSlice({
  name: "globalFieldsState",
  initialState,
  reducers: {
    getWaterCompanies(state, action: PayloadAction<QueryParams>) {
      state.isLoadingWaterCompanies = true;
    },
    setWaterCompanies(state, action: PayloadAction<WaterCompanyType[]>) {
      state.isLoadingWaterCompanies = false;
      state.waterCompanies = action.payload;
    },
    getWaterCompanyComplete(state) {
      state.isLoadingWaterCompanies = false;
    },
    getCities(state) {
      state.isLoadingCities = true;
    },
    setCities(state, action: PayloadAction<CityType[]>) {
      state.isLoadingCities = false;
      state.cities = action.payload;
    },
    getSites(state, action: PayloadAction<QueryParams>) {
      state.isLoadingSites = true;
    },
    setSites(state, action: PayloadAction<StateType[]>) {
      state.isLoadingSites = false;
      state.sites = action.payload;
    },
    setSite(state, action: PayloadAction<{ label: string; value: string }>) {
      state.site = action.payload;
    },

    setWaterCompany(
      state,
      action: PayloadAction<{ label: string; value: string }>
    ) {
      set(state, "waterCompany", action.payload);
    },
    setCity(state, action: PayloadAction<{ label: string; value: string }>) {
      set(state, "city", action.payload);
    },

    getUserGroups(state, action: PayloadAction<QueryParams>) {
      state.isLoadingUserGroups = true;
    },
    setUserGroups(state, action: PayloadAction<{ groups: UserGroups[] }>) {
      state.userGroups = action.payload?.groups;
      state.isLoadingUserGroups = false;
    },
    setUserGroupsError(state) {
      state.isLoadingUserGroups = false;
    },
  },
});

export const {
  actions,
  reducer,
  name: globalFieldsSliceKey,
} = globalFieldsSlice;
