import { Box, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CustomButton from "app/components/Button";
import TextInput from "app/components/TextInput";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLgStyles } from "../LoginPage/styles";

export function LoginForm({ className }) {
  const classes = useLgStyles();
  const { t } = useTranslation();

  return (
    <Grid2 height={"100%"} container>
      <Grid2 height={"100%"} xsOffset={2} xs={8}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <TextInput
            placeholder={t("AUTH.EMAIL")}
            containerStyle={{
              width: "100%",
              marginBottom: "9px",
            }}
            type="email"
          />
          <TextInput
            placeholder={t("AUTH.PHONE_NUMBER")}
            containerStyle={{
              width: "100%",
              marginBottom: "9px",
            }}
          />
          <TextInput
            placeholder={t("AUTH.PASSWORD")}
            containerStyle={{
              width: "100%",
              marginBottom: "9px",
            }}
          />
          <TextInput
            placeholder={t("AUTH.REPEAT_PASSWORD")}
            containerStyle={{
              width: "100%",
            }}
          />
          <CustomButton
            btnStyle={{
              marginTop: "52px",
            }}
          >
            <Typography className={classes.btnText}>
              {t("BUTTONS.SIGN_UP")}
            </Typography>
          </CustomButton>
        </Box>
      </Grid2>
    </Grid2>
  );
}

export default LoginForm;
