import { PermissionRowType } from "app/containers/Permissions/type";

export const APP_API_URL =
  process.env.REACT_APP_API_URL 

export const APP_VERSION = process.env.REACT_APP_VERSION || "";
export const APP_PUBLIC_URL = process.env.PUBLIC_URL || "";
export const IS_PRODUCTION = process.env.NODE_ENV !== "production";
export const API_URL = APP_API_URL;
export const LOGIN_HEADER_HEIGHT = 96;
export const DASHBOARD_MENU_WIDTH = 217;
export const DASHBOARD_TOP_BAR_HEIGHT = 80;
export const DASHBOARD_MENU_CLOSE_WIDTH = 96;
export const DASHBOARD_TOP_MENU_CLOSE_WIDTH = 48;

export const statusFilterOptions = [
  { label: "All", value: "" },
  { label: "Active", value: "TRUE" },
  { label: "In-active", value: "FALSE" },
];

export const statusDropdownItems = [
   { name: "All", value: "All" }, 
   { name: "Active", value: "TRUE" }, 
   { name: "Inactive", value: "FALSE"}
 ];

export const monthDropdownItems = [
  { name: "All", value: "0" },
  { name: "January", value: "1" }, 
  { name: "February", value: "2" }, 
  { name: "March", value: "3" }, 
  { name: "April", value: "4" }, 
  { name: "May", value: "5" }, 
  { name: "June", value: "6" }, 
  { name: "July", value: "7" }, 
  { name: "August", value: "8" }, 
  { name: "September", value: "9" }, 
  { name: "October", value: "10" }, 
  { name: "November", value: "11" }, 
  { name: "December", value: "12" }, 
];

export const statusList = [
  {
    id: 0,
    name: 'All',
    value: 'all'
  },
  {
    id: 1,
    name: 'Pending',
    value: 'pending'
  },
  {
    id: 2,
    name: 'Failed',
    value: 'failed'
  },
  {
    id: 3,
    name: 'Recharge',
    value: 'recharge'
  },
  {
    id: 4,
    name: 'Success',
    value: 'success'
  }
];

export const statusListForRow = [
  {
    id: 1,
    name: 'Failed',
    value: 'failed'
  },
  {
    id: 2,
    name: 'Recharge',
    value: 'recharge'
  },
]


export const Colors = {
  green: "#58BC7C",
  yellow: "#DCD526",
  blue: "#4596FF",
  grey: "#9FA2A5",
  pink: "#ECD1B9",
}



export function createData(
  firstName: string,
  lastName: string,
  userGroup: string,
  mobileNumber: string,
  waterCompany: string,
  email: string,
  site: string,
  createdAt: string,
  updatedAt: string,
  lastLogin: string,
) {
return {
  firstName,
  lastName,
  userGroup,
  mobileNumber,
  waterCompany,
  email,
  site,
  createdAt,
  updatedAt,
  lastLogin
}
}

export const UserRows = [];

export const ConsumerRows = []

export const Permissions:PermissionRowType[] = [];
