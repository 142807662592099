import { FormError } from "app/containers/types";
import { LoginForm } from "../../types";

export function validate(form: LoginForm): Array<FormError> {
  const errors: Array<FormError> = [];
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(form.email.value)) {
    errors.push({
      name: "email",
      error: "COMMON.USERS.ERRORS.USER_NOT_FOUND",
    });
  }
  if (!form.password.value) {
    errors.push({
      name: "password",
      error: "COMMON.USERS.ERRORS.USER_PASSWORD_REQUIRED",
    });
  }

  return errors;
}
