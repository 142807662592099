/**
 * Gets the repositories of the user from Github
 */

import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { Translate } from "utils/helpers";
import { request } from "utils/request";
import { LoggedInUser } from "../types";
import { actions, logoutSuccess } from "./slice";

/**
 * Github repos request/response handler
 */

export function* resetPassword(action) {
  const code = action.payload?.token;
  const email = action.payload?.email;
  const password = action.payload?.password;
  const options = {
    method: "POST",
    body: JSON.stringify({ code, password, email }),
  };
  try {
    const response = yield call(
      request,
      `${API_URL}/v1/passwords/reset`,
      options
    );
    if (response?.message !== "USERS.PASSWORD.RESET_SUCCESS") {
      throw new Error("COMMON.USERS.PASSWORD.RESET_FAILED");
    }
    yield put(actions.resetPasswordSuccess());
    yield call(action?.payload?.callback);
  } catch (e: any) {
    const error = JSON.parse(e?.message)?.errors;
    toast(Translate(`COMMON.${error?.message ?? e?.message}`));
    yield put(actions.loginError(`COMMON.${error ?? e?.message}`));
    return;
  }
}

export function* forgotPassword(action) {
  const email = action.payload?.email;
  const options = {
    method: "POST",
    body: JSON.stringify({ email }),
  };
  try {
    const response = yield call(
      request,
      `${API_URL}/v1/passwords/forgot`,
      options
    );

    if (response.message !== "USERS.EMAIL.RESET_EMAIL_SENT") {
      throw new Error("COMMON.USERS.EMAIL.RESET_EMAIL_NOT_SENT");
    }
    yield put(actions.forgotPasswordSuccess());
    yield call(action?.payload?.callback);
  } catch (err: any) {
    const error = JSON.parse(err?.message)?.errors;
    yield put(actions.setForgotError(`${error?.message ?? err?.message}`));
    return;
  }
}

export function* logoutRequest() {
  yield delay(500);

  const options = {
    method: "POST",
  };

  try {
    const response = yield request(`${API_URL}/auth/logout`, options);
    if (response) {
      yield localStorage.setItem("sessionToken", "");
    }
  } catch (err: any) {}

  yield put(logoutSuccess());
}

export function* loginRequest(action) {
  const options = {
    method: "POST",
    body: JSON.stringify({
      email: action.payload?.email,
      password: action.payload?.password,
    }),
  };

  try {
    const response = yield request(`${API_URL}/v1/auth/login`, options);
    if (!response?.token) {
      throw new Error("COMMON.INVALID_EMAIL_PASSWORD");
    }
    yield localStorage.setItem("sessionToken", response.token);
    yield put(actions.loginSuccess(response));
    yield call(action.payload?.callback);
  } catch (err: any) {
    let error = err?.message;
    try {
      error = JSON.parse(err?.message)?.errors;
    } catch (error: any) {
      console.log(error?.message);
    }
    toast(Translate(`COMMON.${error?.message ?? error}`));
    yield put(actions.loginError(`COMMON.${error?.message ?? err?.message}`));
  }
}

export function* getMeApi(action: any) {
  try {
    const options = {
      method: "GET",
    };
    const url = `${API_URL}/v1/auth/me`;
    const response: LoggedInUser = yield call(request, url, options);
    yield put(actions.getUserSuccess(response));
  } catch (err: any) {
    const error = JSON.parse(err?.message)?.errors;
    toast(Translate(`COMMON.${error?.message ?? err?.message}`));
    action?.payload?.callback();
    return;
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* authSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.login.type, loginRequest);
  yield takeLatest(actions.logout.type, logoutRequest);
  yield takeLatest(actions.forgotPassword.type, forgotPassword);
  yield takeLatest(actions.resetPassword.type, resetPassword);
  yield takeLatest(actions.getUser.type, getMeApi);
}
