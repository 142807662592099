import { Box, Link, Typography, useTheme } from "@mui/material";
import CustomButton from "app/components/Button";
import TextInput from "app/components/TextInput";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectLoading, selectLoginForm } from "../../redux/selectors";
import { actions } from "../../redux/slice";
import { useLgStyles } from "./styles";
import { validate } from "./validator";

export function LoginForm({ className }) {
  const classes = useLgStyles();
  const formValues = useSelector(selectLoginForm);
  const isLoading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const [error, setError] = useState("");

  const emailValue = formValues.email.value;
  const passwordValue = formValues.password.value;
  const emailError = formValues.email.error;
  const passwordError = formValues.password.error;

  const handleChange = (e) => {
    setError("");
    const { name, value } = e.target;
    dispatch(actions.setForm({ key: name, value }));
  };

  const onSubmit = (e) => {
    e?.preventDefault();
    const errors = validate(formValues);
    if (errors?.length > 0) {
      dispatch(actions.setFormErrors(errors));
      return;
    }
    dispatch(
      actions.login({
        email: emailValue,
        password: passwordValue,
        callback: () => {
          navigate("/");
        },
      })
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        padding: "0 20%",
        direction: theme.direction,
      }}
    >
      <form>
        <Typography className={classes.title}>{t("AUTH.TITLE")}</Typography>
        <TextInput
          name="email"
          placeholder={t("AUTH.EMAIL")}
          containerStyle={{
            width: "100%",
            marginBottom: "9px",
          }}
          type="email"
          value={emailValue}
          onChange={handleChange}
          error={emailError && t(`${emailError}`)}
          disabled={isLoading}
        />
        <TextInput
          name="password"
          placeholder={t("AUTH.PASSWORD")}
          containerStyle={{
            width: "100%",
          }}
          value={passwordValue}
          onChange={handleChange}
          error={passwordError && t(`${passwordError}`)}
          type="password"
          disabled={isLoading}
        />
        <Link href="/auth/forgot-password" className={classes.forgetPassword}>
          {t("AUTH.FORGOT_PASSWORD")}
        </Link>
        <Typography className={classes.error}>{error}</Typography>
        <CustomButton
          disabled={emailValue === "" || passwordValue === "" || isLoading}
          btnStyle={{
            marginTop: "32px",
          }}
          type="submit"
          onClick={onSubmit}
          isLoading={isLoading}
        >
          <Typography className={classes.btnText}>
            {t("BUTTONS.LOGIN")}
          </Typography>
        </CustomButton>
        <Typography
          sx={{
            marginTop: "32px",
            textAlign: "center",
            color: "#9CA2B0",
          }}
        >
          {t("PROFILE.VERSION", { version: process.env.REACT_APP_VERSION })}
        </Typography>
      </form>
    </Box>
  );
}

export default LoginForm;
