/**
 * Homepage selectors
 */

import { RootState } from "types";

import { createSelector } from "@reduxjs/toolkit";

import { PermissionKeys } from "app/containers/Permissions/type";
import { selectCapabilitiesForPermissions } from "store/fields/permissions/selectors";
import { initialState } from "./slice";

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.profileState || initialState;
const selectUser = (state: RootState) => state.globalPermissionState.user;

export const selectFirstName = createSelector(
  [selectDomain],
  (profileState) => profileState.firstName
);

export const selectLastName = createSelector(
  [selectDomain],
  (profileState) => profileState.lastName
);

export const selectPhoneNumber = createSelector(
  [selectDomain],
  (profileState) => profileState.phoneNumber
);

export const selectEmail = createSelector(
  [selectDomain],
  (profileState) => profileState.email
);

export const selectLanguage = createSelector(
  [selectDomain],
  (profileState) => profileState.language
);

export const selectImage = createSelector(
  [selectDomain],
  (profileState) => profileState.image
);

export const selectIsUploading = createSelector(
  [selectDomain],
  (profileState) => profileState.isUploading
);

export const selectIsLoading = createSelector(
  [selectDomain],
  (profileState) => profileState.isLoading
);

export const selectError = createSelector(
  [selectDomain],
  (profileState) => profileState.error
);

export const selectedUser = createSelector([selectUser], (user) => user);

export const selectHasProfileAccess = createSelector(
  [selectCapabilitiesForPermissions],
  (capabilitiesForPermissions) => {
    return capabilitiesForPermissions[PermissionKeys.PROFILE_EDIT_INFO];
  }
);
