import { Box, Typography, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import SiteSelect from "app/components/SiteSelect";
import WaterCompaniesSelect from "app/components/WaterCompaniesSelect";
import { PermissionKeys } from "app/containers/Permissions/type";
import groupIcon from "assets/users-group.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCapabilitiesForPermissions } from "store/fields/permissions/selectors";
import { selectTotalUsers } from "../../redux/selectors";

export default function Header() {
  const totalUsers = useSelector(selectTotalUsers);
  const { t } = useTranslation();
  const theme = useTheme();
  const capabilitiesForPermissions = useSelector(
    selectCapabilitiesForPermissions
  );

  const canViewStats =
    capabilitiesForPermissions[PermissionKeys.DASHBOARD_VIEW_STATS];

  return (
    <Grid2 spacing={3} sx={{ marginBottom: "40px" }} container>
      <Grid2 sm={3}>
        <Box
          sx={{
            direction: theme.direction,
          }}
        >
          <WaterCompaniesSelect />
        </Box>
      </Grid2>
      <Grid2 sm={3}>
        <Box
          sx={{
            direction: theme.direction,
          }}
        >
          <SiteSelect />
        </Box>
      </Grid2>
      <Grid2 smOffset={2} sm={4} lgOffset={3} lg={3}>
        {canViewStats.hasReadAccess && (
          <Box
            sx={{
              width: "100%",
              filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05))",
              height: "100%",
              background: "#FFFFFF",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "9px 21px",
              direction: theme.direction,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                color: "#4596FF",
              }}
            >
              <Typography
                sx={{
                  fontSize: "32.211px",
                  fontWeight: 700,
                }}
              >
                {totalUsers ?? 0}
              </Typography>
              <Typography
                sx={{
                  fontSize: "19.557px",
                  fontWeight: 400,
                  lineHeight: "25.302px",
                }}
              >
                {t("DASHBOARD.TOTAL_USERS")}
              </Typography>
            </Box>
            <Box>
              <img src={groupIcon} alt="users-group" width="32px" />
            </Box>
          </Box>
        )}
      </Grid2>
    </Grid2>
  );
}
