import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { selectLanguage } from "store/fields/permissions/selectors";
import { actions } from "store/fields/permissions/slice";
import { themeActions } from "styles/theme/slice";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

export default function Layout({ children }: { children: React.ReactNode }) {
  const auth = localStorage.getItem("sessionToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector(selectLanguage);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!language) return;
    localStorage.setItem("i18nextLng", language);
    i18n.changeLanguage(language?.toLowerCase());
    dispatch(
      themeActions.toggleDirection(language?.toLowerCase() as "en" | "he")
    );
  }, [language, dispatch, i18n]);

  useEffect(() => {
    if (auth) {
      dispatch(
        actions.getUser({
          callback: () => {
            localStorage.removeItem("sessionToken");
            navigate("/auth/login");
          },
        })
      );
    }
  }, [auth, dispatch]);

  if (!auth) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <Box sx={{ background: "#FEFEFE", height: "100vh", overflow: "clip" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Sidebar />
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <Navbar />
          <Box
            sx={{
              height: "calc(100vh - 64px)",
              overflow: "auto",
              padding: "32px 32px",
              background: "#FEFEFE",
              position: "relative",
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
